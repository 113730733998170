<template>
  <div class="data-person-dashboard">
    <template v-if="dashboard">
      <data-params-editor
        :schema="dashboard.params"
        v-model="params"
        @input="onParamsChanged"
      ></data-params-editor>

      <div class="views">
        <ui-item
          v-for="(view, i) in dashboard.views"
          :key="i"
          class="ui-clickable"
          v-bind="view.card"
          :secondary="view.record ? $ts(view.record.dateModified) : 'Sin registro'"
          @click="onClickView(view)"
          badge=" "
          :badge-color="view.record ? 'var(--ui-color-success)' : 'var(--ui-color-danger)'"
        >
          <template #icon>
            <ui-avatar
              :title="view.card.text"
              :src="view.card.icon"
            ></ui-avatar>
          </template>
        </ui-item>
      </div>

      <ui-dialog
        :open="!!currentView"
        @close="currentView = null"
      >
        <data-person-view
          v-if="currentView"
          ref="curview"
          :person-id="personId"
          :view-id="currentView.id"
          :keys="currentView.keys"
          @update:is-valid="isValid = $event"
        ></data-person-view>

        <template #footer="{ close }">
          <button
            type="button"
            class="ui-button"
            :class="{'--main': isValid, '--danger': !isValid}"
            @click="$refs.curview.save().then(reload).then(close).catch(()=>{})"
          >Aceptar</button>
          <button
            type="button"
            class="ui-button --cancel"
            @click="close"
          >Cancelar</button>
        </template>
      </ui-dialog>
    </template>
  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi.js';
import apiDataDashboards from '@/modules/data/api/dashboards.js';

import { UiItem, UiAvatar, UiDialog } from '@/modules/ui/components';
import DataParamsEditor from '@/modules/data/components/Params/ParamsEditor.vue';
import DataPersonView from '@/modules/data/components/Person/View.vue';

export default {
  name: 'data-person-dashboard',
  mixins: [useI18n, useApi],
  $api: apiDataDashboards,

  components: {
    UiItem,
    UiAvatar,
    UiDialog,
    DataParamsEditor,
    DataPersonView,
  },

  props: {
    personId: {
      type: String,
      required: true,
    },

    dashboardId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dashboard: null,
      params: {},
      currentView: null,

      isValid: false
    };
  },

  mounted() {
    this.fetchDashboard();
  },

  watch: {
    personId: {
      handler() {
        this.dashboard = null;
        this.fetchDashboard();
      },
    },

    dashboardId: {
      handler() {
        this.dashboard = null;
        this.fetchDashboard();
      },
    },
  },

  methods: {
    onParamsChanged() {
      this.fetchDashboard();
    },

    onClickView(view) {
      if (view.hideForm) {
        this.currentView = null;
      } else {
        if (!view.record) {
          this.currentView = view;
        }
      }
    },

    async fetchDashboard() {
      this.dashboard = await this.$api.getPersonDashboard(
        this.personId,
        this.dashboardId,
        this.params
      );

      this.dashboard.params.forEach((param) => {
        if (param.value) {
          this.params[param.name] = param.value;
        }
      });

      this.$emit('load', this.dashboard);
    },

    reload() {
      return this.fetchDashboard();
    },
  },
};
</script>