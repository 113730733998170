<template>
  <div class="data-params-editor ui-row --tight">
    <ui-field
      v-for="field in validFields"
      :key="field.name"
      :label="field.label"
      :message="field.description"
    >
      <ui-input-date
        v-if="field.type == 'day'"
        format="day"
        v-model="innerValues[field.name]"
        v-bind="field.props"
        @input="emitInput"
      ></ui-input-date>
      <v3-person-picker
        v-if="field.type == 'person'"
        v-bind="field.props"
        @input="innerValues[field.name] = $event.id; emitInput()"
      ></v3-person-picker>
    </ui-field>
  </div>
</template>

<script>
import { UiField, UiInputDate } from '@/modules/ui/components';
import V3PersonPicker from '@/modules/v3/components/V3PersonPicker/V3PersonPicker.vue';

export default {
  name: 'data-params-editor',
  components: {
    UiField,
    UiInputDate,
    V3PersonPicker,
  },

  props: {
    schema: {
      type: Array,
      required: true,
    },

    value: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      innerValues: {},
    };
  },

  computed: {
    validFields() {
      return this.schema.filter((s) => ['day', 'person'].includes(s.type));
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.innerValues = newValue ? JSON.parse(JSON.stringify(newValue)) : {};
      },
    },
  },

  methods: {
    emitInput() {
      this.$emit('input', JSON.parse(JSON.stringify(this.innerValues)));
    },
  },
};
</script>