export default function (httpClient) {
  return {
    // Administracion
    getView(viewId) {
      return httpClient.get(`/1/data/views/${viewId}`);
    },

    postView(viewId, data) {
      return httpClient.post(`/1/data/views/${viewId}`, data);
    },

    // Gestion de registros
    getPersonView(viewId, personId, keys) {
      return httpClient.get(`/1/data/people/${personId}/views/${viewId}`, keys);
    },

    postPersonView(viewId, personId, keys, postData) {
      return httpClient.post(`/1/data/people/${personId}/views/${viewId}`, keys, postData);
    }
  }
}